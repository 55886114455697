import dayjs from 'dayjs';
import { capitalizeText } from './etc';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
dayjs.extend(timezone);

const formatNumberWithZeros = (number) => {
  const numberStr = number.toString();
  if (numberStr.length < 8) {
    return numberStr.padStart(8, '0');
  }
  return numberStr;
}

const printHeader = `
  <div class="flex-container">
    <div>
      <img width="65px" src='/static/images/logo.png' />
    </div>
    <div class="company-header">
      XIAO QIANG HOLIDAYS SDN.BHD (Company No.1047644-K) KPK/LN: 7371 <br/>
      <p class="company-contact">
        26 & 28 1st Floor, Jalan Pandak Mayah 4, Mukim Kuah 07000 Langkawi Kedah, Mukim Kuah, 07000 Langkawi, Kedah Darul Aman, Malaysia. <br/>
        Tel: +604-9665576 <br/>
        Web: https://eticket.xqholidays.com.my
      </p>
    </div>
  </div>
`;

const printDetail = (voucher) => `
<div>
  <table class="full-width">
    <tr>
      <td class="table-header"></td>
      <td class="table-header" style="font-family: 'Stencil', 'OCR-A', 'SimHei', sans-serif; padding-right: 25px; font-size:18px; text-align: right;">No. Voucher: <span style="color:red">${formatNumberWithZeros(voucher.id)}</span></td>
    </tr>
     <tr>
      <td class="table-header">INVOICE ID:</td>
      <td class="table-header">HOTEL</td>
    </tr>
    <tr>
      <td class="table-content"><input value="${voucher.invoiceId}" /></td>
      <td class="table-content"><input value="${voucher.itemName}" /></td>
    </tr>
    <tr class="table-row-spacer">
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td class="table-header">GUEST:</td>
      <td class="table-header">DATE:</td>
    </tr>
    <tr>
      <td class="table-content"><input value="${voucher.name}" /></td>
      <td class="table-content"><input value="${dayjs.utc(voucher.date).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')}" /></td>
    </tr>
    <tr class="table-row-spacer">
      <td></td>
      <td></td>
    </tr>
  </table>
</div>
`;

const printRooms = (rooms) => {
  const renderItemsHTML = rooms.map((i) => `
    <tr style="border-bottom: 0.5px solid;">
      <td class="table-cell-padding">${i.type}</td>
       <td class="table-cell-padding">${dayjs.utc(i.checkIn).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')}</td>
      <td class="table-cell-padding">${dayjs.utc(i.checkOut).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')}</td>
      <td class="table-cell-padding" style="text-align: right">${dayjs.utc(i.checkOut).tz('Asia/Kuala_Lumpur').diff(dayjs.utc(i.checkIn).tz('Asia/Kuala_Lumpur'), 'day')}</td>
      <td class="table-cell-padding" style="text-align: right">${i.quantity}</td>
    </tr>`);
  return `
    <div>
      <table class="full-width" style="border-collapse: collapse; padding: 10px; border-style: solid; border-width: 0.5px;">
        <tr style="border-bottom: 0.5px solid">
          <th class="table-cell-padding" style="text-align: left">ROOM TYPE</th>
          <th class="table-cell-padding" style="text-align: left">CHECK IN</th>
          <th class="table-cell-padding" style="text-align: left">CHECK OUT</th>
          <th class="table-cell-padding" style="text-align: right">DURATION</th>
          <th class="table-cell-padding" style="text-align: right">UNIT</th>
        </tr>
        ${renderItemsHTML.join(' ')}
      </table>
    </div>
  `;
}

export const print = (voucher) => {
  const html = `
  <!DOCTYPE html>
  <html>
     <head>
        <style>
           .flex-container {
            display: flex;
           }
           .flex-container > div {
            margin: 10px;
            padding: 10px 5px;
           }
           .company-header {
            font-size: 16px;
            font-weight: bold;
           }
           .company-contact {
            font-size: 12px;
            font-weight: normal;
           }
           .full-width {
             width: 100%;
             border-spacing: 0 5px;
           }
           .table-header {
              font-weight: bold;
              width: 10%;
              font-size: 14px;
           }
           .table-content {
              width: 40%;
              padding-right: 5px;
           }
           .table-cell-padding {
             vertical-align: top;
             padding: 10px 10px;
           }
            .table-row-spacer td {
              height: 12px; /* Adjust space */
            }
            input {
              font-size: 16px; 
              height:36px; 
              width: 350px;
              padding-left: 10px;
            }
           .stamp {
            display: inline-block;
            font-weight: bold;
            font-size: 24px;
            text-transform: uppercase; /* Make it look official */
            border: 3px solid; /* Outline like a stamp */
            padding: 10px 20px; /* Space inside the stamp */
            border-radius: 10px; /* Rounded corners */
            transform: rotate(-20deg); /* Slight rotation */
           
            font-family: 'Courier New', Courier, monospace; /* Stamp-like font */
          }
          
          .stamp-red {
            color: red; 
            border-color: red;
            background-color: rgba(255, 0, 0, 0.1); /* Light red background */
          }
          .stamp-orange {
            color: orange;
            border-color: orange;
            background-color: rgba(255, 165, 79, 0.2);
          }
        </style>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
          $(document).ready(function(){
            //setTimeout(function(){ window.print(); }, 500);
          });
        </script>
     </head>
     <body style="width: 794px">
        ${printHeader}
        <div style="text-align: center">
          <h2 style="padding: 0px 5px"><u>RESERVATION VOUCHER</u></h2>
          ${voucher.stamping && voucher.status === 'Cancelled' ? '<div style="position: absolute; margin-top: -60px; margin-left: -350px" class="stamp stamp-red">CANCELLED</div>' : ''}
          ${voucher.stamping && voucher.status === 'Amended' ? '<div style="position: absolute; margin-top: -60px; margin-left: -350px" class="stamp stamp-orange">AMENDED</div>' : ''}
        </div>
        <div style="padding: 20px">
          ${printDetail(voucher)}
          ${printRooms(voucher.rooms)}

          <h4>REMARK</h4>
          <div style="min-height: 100px; padding: 10px; border-width: 0.5px; border-style: solid; overflow: auto; white-space: pre-wrap; word-wrap:break-word">${voucher.remark}</div>
        </div>
       
     </body>
     </html>
  `;
  return html;
};