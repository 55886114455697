import * as React from 'react';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import dayjs from 'dayjs';
import {
  Button,
  TextField,
  InputLabel,
  DialogTitle,
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Checkbox
} from '@mui/material';
import AppContext from '../../context';
import { dateFormat } from '../../utils/etc';
import { getReservationVoucher, addReservationVoucher, updateReservationVoucher } from '../../services/reservation-service';
import { print } from '../../utils/printVoucher';
import Rooms from './Rooms';
import { getLoginSession } from '../../services/auth-service';

const DialogVoucher = ({ selected, close }) => {
  const newState = {
    id: null,
    date: dayjs().startOf('day').format(dateFormat),
    name: selected['Invoice.name'],
    itemName: selected['Item.name'],
    rooms: [],
    stamping: false,
    status: 'New',
    remark: '',
  };

  const appContext = React.useContext(AppContext);
  const [error, setError] = React.useState({});
  const [data, setData] = React.useState(newState);
  const [printable, setPrintable] = React.useState(false);
  const session = getLoginSession();

  React.useState(() => {
    const loadData = async () => {
      appContext.setLoading(true);
      try {
        const result = await getReservationVoucher(selected.invoiceId + '_' + selected['Item.id']);
        if (Object.keys(result).length !== 0) {
          setPrintable(true);
          setData({
            ...result,
            rooms: result.rooms.map(r => ({
              ...r,
              checkIn: dayjs(r.checkIn),
              checkOut: dayjs(r.checkOut),
            }))
          });
        }
      } catch (e) {
        appContext.showMessage('error', `Error in procesing this request: ${e}`);
        console.error(e);
      } finally {
        appContext.setLoading(false);
      }
    };
    loadData();
  }, []);

  const validate = () => {
    const maybeError = {
      ...error,
      id: !data.id || data.id === '',
      name: !data.name || data.name === '',
      code: !data.code || data.code === '',
      item: !data.item || data.item === '',
      type: !data.type || data.type === '',
    };
    setError(maybeError);
  };

  const handleInputChange = (e) => {
    setPrintable(false);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const save = async () => {
    validate();
    if ((!data.name || data.name === '')
      || (!data.itemName || data.itemName === '')
      || (data.rooms.length === 0)) {
      appContext.showMessage('warning', 'Please fill in all the required fields and make sure there is at least 1 room type.');
      return;
    }
    try {
      appContext.setLoading(true);
      if (!data.id) {
        const response = await addReservationVoucher(
          selected.invoiceId,
          selected['Item.id'],
          data.date,
          data.name,
          data.itemName,
          data.rooms.map(r => ({ ...r, checkIn: r.checkIn.format('YYYY-MM-DD'), checkOut: r.checkOut.format('YYYY-MM-DD') })),
          data.stamping,
          data.status,
          data.remark
        );
        setData({ ...data, id: response.id, invoiceId: selected.invoiceId });
      } else {
        await updateReservationVoucher(
          data.id,
          data.date,
          data.name,
          data.itemName,
          data.rooms.map(r => ({ ...r, checkIn: r.checkIn.format('YYYY-MM-DD'), checkOut: r.checkOut.format('YYYY-MM-DD') })),
          data.stamping,
          data.status,
          data.remark
        );
      }
      appContext.showMessage('success', `Voucher updated successfully.`);
      setPrintable(true);
    } catch (e) {
      console.error(e);
      appContext.showMessage('error', `Voucher update failed: ${e.response.data}`);
    } finally {
      appContext.setLoading(false);
    }
  };

  const updateRooms = (rooms) => {
    setPrintable(false);
    setData({ ...data, rooms, });
  }

  return (
    <Dialog fullWidth maxWidth="md" open onClose={close}>
      <DialogTitle>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h4" component="div">
              {`${selected.invoiceId} | Voucher`}
            </Typography>
            <Button
              disabled={!printable}
              color="inherit"
              onClick={() => {
                const printWindow = window.open("", "print", "width=850,top=300,left=300, height=" + window.screen.availHeight * 0.8 + ",scrollbars=1,resizable=1");
                printWindow.document.open();
                printWindow.document.write(print(data));
                printWindow.document.close();
              }}
            >
              Print
            </Button> |
            <Button autoFocus onClick={save} color="inherit" disabled={data.id && session.role >= 2}>
              save
            </Button> |
            <Button autoFocus onClick={() => close(true)} color="inherit">
              Close
            </Button>
          </Toolbar>
        </AppBar>

      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Date</InputLabel>
              <MobileDatePicker
                size="small"
                renderInput={(params) => <TextField label="Date" fullWidth size="small" {...params} />}
                onChange={(newValue) => {
                  handleInputChange({ target: { name: 'date', value: newValue } });
                }}
                value={data.date}
                inputFormat="YYYY-MM-DD"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                value={data.status}
                labelId="status-select-label"
                id="status"
                name="status"
                label="Status"
                size="small"
                onChange={handleInputChange}
              >
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Amended">Amended</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={data.itemName}
              error={error.itemName}
              helperText={error.itemName ? 'Invalid hotel.' : ''}
              id="itemName"
              name="itemName"
              size="small"
              label="Hotel"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <TextField
              value={data.name}
              error={error.name}
              helperText={error.name ? 'Invalid guest name.' : ''}
              id="name"
              name="name"
              size="small"
              label="Guest Name"
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Stamping</InputLabel>
            <Checkbox checked={data.stamping} onChange={(e, v) => handleInputChange({ target: { name: 'stamping', value: v } })} sx={{ width: 50 }} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ marginTop: -2, paddingLeft: 1, paddingBottom: 2, fontSize: 12 }} size="small">Rooms</InputLabel>
            <Rooms rooms={data.rooms} updateRooms={updateRooms} />
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <TextField
              sx={{ marginTop: 2 }}
              value={data.remark}
              fullWidth
              id="remark"
              name="remark"
              label="Remark"
              multiline
              rows={5}
              inputProps={{ maxLength: 5000 }}
              helperText="Max 5000 characters"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <br /><br />
      </DialogContent>
    </Dialog>
  );
};


export default DialogVoucher;
