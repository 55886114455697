import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Card, CardContent, Tabs, Tab,
  Box,
  Typography,
  Popover,
  Container,
  Tooltip,
} from '@mui/material';
import DialogInvoice from '../components/invoices/DialogInvoice';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { getInvoices, getOptions } from '../services/invoice-service';
import GridView from '../components/GridView';
import { capitalizeText } from '../utils/etc';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

dayjs.extend(utc);

const renderDueDate = (param) => {
  const style = {};
  if (dayjs(param.value) <= dayjs()) {
    style.color = 'red';
  }
  return <h4 style={style}>{param.value}</h4>;
};
const renderDateTime = (param) => {
  let confirmed = true;
  switch (param.field) {
    case 'arrivalTime':
      confirmed = param.row.arrivalTimeConfirmed;
      break;
    case 'departureTime':
      confirmed = param.row.departureTimeConfirmed;
      break;
    default:
  }
  return confirmed ? dayjs.utc(param.value).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC';
}
const renderDate = (param) => dayjs.utc(param.value).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD');
const renderPendingPayment = (param) => {
  return <Box sx={{ display: 'flex' }}>
    {param.value > 0 && <Tooltip title="Pending Payment Verification"><PaidOutlinedIcon sx={{ paddingRight: 1, marginTop: 1.5 }} color="primary" /></Tooltip>}
    {param.row.payment && <span>{capitalizeText(param.row.payment)}</span>}
  </Box>
}

const RenderLongText = (params) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isLongText = params.value && params.value.length > 20;
  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {isLongText ? params.value.substring(0, 20) + '...' : params.value}
      </Typography>
      {isLongText &&
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, overflowX: 'auto', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{params.value}</Typography>
        </Popover>
      }
    </div>
  );
}

const Invoices = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    tab: 'new',
    data: {
      rows: [],
      count: 0
    },
    selectedInvoice: null
  });

  const loadOptions = async () => {
    try {
      const options = await getOptions();
      appContext.setOptions(options);
    } catch {
      appContext.showMessage('Error', 'Some error had occured in getting options. Please try again.');
    }
  }

  const columns = [
    {
      field: 'dueDate', headerName: 'Due Date', renderCell: renderDueDate, width: 100
    },
    {
      field: 'pendingPayment',
      headerName: 'Payment',
      renderCell: renderPendingPayment
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 110,
      renderCell: (params) => {
        return <>{params.value}{(params.row.warning && ['new', 'booking'].includes(state.tab)) ? <WarningAmberIcon sx={{ fontSize: 16, mt: -0.5 }} /> : null}</>;
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'contact',
      headerName: 'Contact No.',
      width: 120,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
      minWidth: 200,
      renderCell: RenderLongText
    },
    {
      field: 'arrivalTime', headerName: 'Arrival Time', width: 140, valueGetter: renderDateTime
    },
    {
      field: 'departureTime', headerName: 'Departure Time', width: 140, valueGetter: renderDateTime
    },
    {
      field: 'assigneeName',
      headerName: 'Assignee',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'createdAt', headerName: 'Created Date', width: 140, valueGetter: renderDate
    },
    {
      field: 'updatedAt', headerName: 'Updated At', width: 140, valueGetter: renderDate
    },
    {
      field: 'updater', headerName: 'Updated By', width: 140,
    },
  ];

  const filteredColumns = state.tab === 'unpaid' ? columns : columns.filter((c) => c.field !== 'pendingPayment')

  useEffect(() => {
    loadOptions();
    search('', 0);
  }, [state.tab]);

  const changeTab = (event, value) => {
    setState({ ...state, tab: value });
  };

  const search = async (searchText, page) => {
    try {
      appContext.setLoading(true);
      const result = await getInvoices(state.tab, searchText, page, configs.pageSize);
      setState({
        ...state, page, invoice: { id: 'New', open: false }, data: result
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Tabs
                value={state.tab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                onChange={changeTab}
              >
                <Tab label="New" value="new" />
                <Tab label="Booking" value="booking" />
                <Tab label="Costing" value="costing" />
                <Tab label="Invoice" value="invoice" />
                <Tab label="Unpaid" value="unpaid" />
                <Tab label="Paid" value="paid" />
                <Tab label="Operating" value="operating" />
                <Tab label="Completed" value="completed" />
                <Tab label="Cancelled" value="cancelled" />
              </Tabs>
              <GridView search={search} columns={filteredColumns} data={state.data} SelectedDialog={DialogInvoice} allowAdd={true} />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Invoices;
