import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AppContext from '../context';
import configs from '../configs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getLoginSession } from '../services/auth-service';
import {
  Card, CardContent,
  Chip,
  Box,
  Typography,
  Container,
  Button,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getInvoices_external } from '../services/invoice-service';
import { capitalizeText } from '../utils/etc';
import InvoiceDialog from '../components/invoices/DialogInvoice';
import { getOptions } from '../services/invoice-service';
dayjs.extend(utc);

const Reminders = () => {
  const appContext = useContext(AppContext);
  const loginSession = getLoginSession();
  const [tab, setTab] = useState(dayjs().startOf('day').format('YYYY-MM-DD'));
  const [state, setState] = useState({
    invoiceId: null,
    data: {
      rows: [],
      count: 0
    },
  });

  const search = async (searchText, page) => {
    try {
      appContext.setLoading(true);
      const result = await getInvoices_external('nextDay', searchText, page, configs.pageSize);
      setState({
        ...state, page, data: result, invoiceId: null
      });
    } catch (e) {
      appContext.showMessage('error', `Error: ${e}`);
      console.error(e);
    } finally {
      appContext.setLoading(false);
    }
  };
  const loadOptions = async () => {
    try {
      const options = await getOptions();
      appContext.setOptions(options);
    } catch {
      appContext.showMessage('Error', 'Some error had occured in getting options. Please try again.');
    }
  }

  const isUpdateAllowed = () => {
    return [1, 2].includes(loginSession.role);
  }

  useEffect(() => {
    if (isUpdateAllowed()) {
      loadOptions();
    }
    search('', 0);
  }, [])

  const close = async () => {
    setState({ ...state, invoiceId: null });
    await search('', 0);
  }

  return (
    <>
      <Helmet>
        <title>Invoices | XQ</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              {state.invoiceId && <InvoiceDialog id={state.invoiceId} close={close} />}
              <Tabs value={tab} onChange={null} aria-label="basic tabs example">
                <Tab onClick={() => setTab(dayjs().subtract(2, 'day').format('YYYY-MM-DD'))} value={dayjs().subtract(2, 'day').format('YYYY-MM-DD')} label={dayjs().subtract(2, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))} value={dayjs().subtract(1, 'day').format('YYYY-MM-DD')} label={dayjs().subtract(1, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().format('YYYY-MM-DD'))} value={dayjs().format('YYYY-MM-DD')} label={dayjs().format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(1, 'day').format('YYYY-MM-DD'))} value={dayjs().add(1, 'day').format('YYYY-MM-DD')} label={dayjs().add(1, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(2, 'day').format('YYYY-MM-DD'))} value={dayjs().add(2, 'day').format('YYYY-MM-DD')} label={dayjs().add(2, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(3, 'day').format('YYYY-MM-DD'))} value={dayjs().add(3, 'day').format('YYYY-MM-DD')} label={dayjs().add(3, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(4, 'day').format('YYYY-MM-DD'))} value={dayjs().add(4, 'day').format('YYYY-MM-DD')} label={dayjs().add(4, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(5, 'day').format('YYYY-MM-DD'))} value={dayjs().add(5, 'day').format('YYYY-MM-DD')} label={dayjs().add(5, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(6, 'day').format('YYYY-MM-DD'))} value={dayjs().add(6, 'day').format('YYYY-MM-DD')} label={dayjs().add(6, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(7, 'day').format('YYYY-MM-DD'))} value={dayjs().add(7, 'day').format('YYYY-MM-DD')} label={dayjs().add(7, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(8, 'day').format('YYYY-MM-DD'))} value={dayjs().add(8, 'day').format('YYYY-MM-DD')} label={dayjs().add(8, 'day').format('YYYY-MM-DD')} />
                <Tab onClick={() => setTab(dayjs().add(9, 'day').format('YYYY-MM-DD'))} value={dayjs().add(9, 'day').format('YYYY-MM-DD')} label={dayjs().add(9, 'day').format('YYYY-MM-DD')} />

              </Tabs>

              {state.data.rows.filter(r => dayjs.utc(r.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD') === tab).length === 0 && <Typography sx={{ p: 2 }}> No reminders in this day.</Typography>}
              {state.data.rows.filter(r => dayjs.utc(r.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD') === tab).map((r) => (
                <Accordion key={r.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{r.id}</Typography><Box sx={{ paddingLeft: 2 }}>
                      <Chip size="small" label={r.status} color={r.status === 'operating' ? 'primary' : 'error'} variant="outlined" />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="text.secondary">
                      {r.arrivalTimeConfirmed ? dayjs.utc(r.arrivalTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(r.pickUp)} <br />
                      {r.departureTimeConfirmed ? dayjs.utc(r.departureTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm') : 'TBC'} {capitalizeText(r.dropOff)} <br />
                      {r.name} <br />
                      <a href={`tel:${r.contact}`}>{r.contact}</a> <br />
                      ID: {r.id} <br />
                      {r.pic} <br />
                      {r.source} <br />
                      {r.carNumbers && <span>{r.carNumbers} <br /></span>}
                      {r.xqCars && <span>{r.xqCars} <br /></span>}
                      {r.notes}
                      <br />
                      {isUpdateAllowed() && <Button variant="outlined" size="small" onClick={() => setState({ ...state, invoiceId: r.id })}>Update</Button>}

                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}

            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Reminders;
